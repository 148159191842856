.services {
  width: 100%;
  padding: 4rem 2rem;
  background: linear-gradient(180deg, #e6eef8, #0f2027);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
}

.services h2 {
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  color: #1a1a1a;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 2rem;
  position: relative;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  justify-content: center;
}

.service-card {
  background-color: #eaf4f7;
  padding: 2rem;
  border-radius: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.service-icon {
  font-size: 3rem;
  margin-bottom: 1.25rem;
  display: inline-block;
}

.service-card h3 {
  color: #333;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.3;
}

.service-card p {
  color: #666;
  font-size: 1rem;
  line-height: 1.6;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .services {
    padding: 3rem 1.5rem;
  }

  .services-grid {
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 1.5rem;
  }

  .service-card {
    padding: 1.5rem;
  }

  .service-icon {
    font-size: 2.5rem;
  }
}

@media (max-width: 768px) {
  .services {
    padding: 2.5rem 1rem;
  }

  .services h2 {
    font-size: 1.75rem;
    margin-bottom: 1.5rem;
  }

  .services-grid {
    grid-template-columns: 1fr;
    gap: 1.25rem;
  }
}

@media (max-width: 480px) {
  .services {
    padding: 2rem 1rem;
  }

  .service-card {
    padding: 1.25rem;
  }

  .service-icon {
    font-size: 2rem;
  }

  .service-card h3 {
    font-size: 1.25rem;
  }
}
