.heroContainer {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: left;
  overflow: hidden;
  padding: 4rem 2rem;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(
    0,
    0,
    0,
    0.7
  ); /* Slightly darker overlay for better contrast */
  z-index: 2;
}

.content {
  position: relative;
  z-index: 3;
  max-width: 1200px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4rem; /* Increased gap for better spacing */
}

.textContent {
  max-width: 600px;
  font-size: 1.8rem;
  color: white;
}

.title {
  font-size: 4rem; /* Slightly larger title */
  font-weight: bold;
  line-height: 1.1;
  margin-bottom: 1.5rem;
}

.gradientText {
  background-image: linear-gradient(90deg, #f5f1f1, #ffbc42);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.text {
  font-size: 1.25rem; /* Slightly larger text */
  margin-bottom: 2.5rem;
  line-height: 1.6;
  opacity: 0.9; /* Slightly reduced opacity for better hierarchy */
}

.buttonGroup {
  display: flex;
  gap: 1rem;
  justify-content: flex-start; /* Align buttons to the left */
}

.primaryButton,
.secondaryButton,
.thirdButton {
  padding: 1rem 2.2rem; /* Slightly larger padding */
  border-radius: 8px; /* Slightly rounder corners */
  font-weight: 600;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: center;
}

.primaryButton {
  background: #7bd7f5;
  border: none;
  color: #111827;
}

.thirdButton {
  background: #0077b5;
  border: none;
  color: white;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
}

.primaryButton:hover {
  background-color: #5cc8e4;
  transform: translateY(-2px);
}

.thirdButton:hover {
  background: #005582;
  transform: translateY(-2px);
}

.profileImageContainer {
  width: 500px; /* Slightly smaller image */
  height: 500px;
  position: relative;
  overflow: hidden;
  border-radius: 12px; /* Rounder corners */
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3); /* Added shadow for depth */
}

.profileImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 1px beige rgba(255, 255, 255, 0.3);
  border-radius: 12px;
}

@media (max-width: 1024px) {
  .content {
    flex-direction: column;
    align-items: center;
    gap: 3rem;
  }

  .textContent {
    font-size: 1.5rem;
    text-align: center;
  }

  .title {
    font-size: 3rem;
  }

  .buttonGroup {
    justify-content: center; /* Center buttons on mobile */
  }

  .profileImageContainer {
    width: 300px;
    height: 300px;
  }
}

@media (max-width: 480px) {
  .heroContainer {
    padding: 2rem 1rem;
  }

  .title {
    font-size: 2.5rem;
  }

  .text {
    font-size: 1.1rem;
  }

  .buttonGroup {
    flex-direction: column;
    width: 100%;
  }

  .primaryButton,
  .thirdButton {
    width: 100%;
    padding: 0.8rem 1.5rem;
  }
}
