.site-footer {
  background: linear-gradient(180deg, #2c5364, hsl(0, 0%, 0%));
  color: white;
  padding: 15px;
  text-align: center;
}

.footer-content {
  max-width: 100%;
  margin: 0 auto;
}

.footer-social {
  margin-bottom: 20px;
}

.footer-social a {
  color: white;
  margin: 0 15px;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-social a:hover {
  color: #3498db;
}

.footer-contact p {
  margin: 10px 0;
}

.site-footer {
  background: linear-gradient(180deg, hwb(210 0% 98%), hsl(0, 0%, 0%));
  color: white;
  padding: 15px 0;
  text-align: center;
  width: 100%;
  margin-top: 0; /* Ensure no top margin */
  margin-bottom: 0; /* Ensure no bottom margin */
}

.footer-content {
  max-width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}
