.featuredSection {
  width: 100%;
  padding: 4rem 2rem;
  background: linear-gradient(180deg, #f8fafd, #e6eef8);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
}

.featuredSection h2 {
  font-size: 1.8rem;
  font-weight: 700;
  text-align: center;
  color: #1a1a1a;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 2rem;
  position: relative;
}

.featuredSection h2::after {
  content: "";
  display: block;
  width: 60px;
  height: 4px;
  background: #3b82f6;
  margin: 0.5rem auto 0;
  border-radius: 2px;
}

.featuredGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  justify-content: center;
}

.featuredCard {
  background: white;
  border-radius: 25px;
  overflow: hidden;
  text-decoration: none;
  color: inherit;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.featuredCard:hover {
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
}

.thumbnail {
  position: relative;
  width: 100%;
  aspect-ratio: 16/9;
  overflow: hidden;
  border-radius: 20px 20px 0 0;
}

.thumbnail::after {
  content: "";
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.2);
  transition: background 0.3s ease;
}

.thumbnail:hover::after {
  background: rgba(0, 0, 0, 0.05);
}

.thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform-origin: center;
  transition: transform 0.3s ease;
}

.thumbnail:hover img {
  transform: scale(1.05);
}

.playButton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.9);
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  color: black;
  font-weight: bold;
  z-index: 1;
  transition: all 0.3s ease;
}

.thumbnail:hover .playButton {
  background: white;
  transform: translate(-50%, -50%) scale(1.1);
}

.cardContent {
  padding: 1.5rem;
  text-align: center;
}

.cardContent h3 {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  line-height: 1.4;
}

.cardContent p {
  font-size: 0.9rem;
  color: #555;
}

/* Responsive Design */
@media (max-width: 768px) {
  .featuredSection {
    padding: 3rem 1rem;
  }

  .featuredGrid {
    gap: 1.5rem;
  }

  .featuredSection h2 {
    font-size: 1.5rem;
  }

  .playButton {
    width: 3rem;
    height: 3rem;
    font-size: 1.2rem;
  }
}

@media (max-width: 480px) {
  .featuredSection {
    padding: 2rem 1rem;
  }

  .featuredGrid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .cardContent {
    padding: 1rem;
  }

  .cardContent h3 {
    font-size: 1rem;
  }
}
