/* NAVIGATION BAR */
.navigation {
  background: #e6eef8;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
  transition: all 0.2s ease-in-out;
  backdrop-filter: blur(15px);
}

/* NAVIGATION CONTAINER */
.navContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 30px;
  max-width: 100%;
  width: 100%;
  margin: auto auto;
  background: linear-gradient(180deg, #e6eef8, #cfebf4, #203a43);
}

/* LOGO */
.logo span {
  font-size: 1.7em;
  color: #2c3e50;
  text-decoration: none;
  font-weight: 700;
  transition: all 0.3s ease-in-out;
  position: relative;
  cursor: pointer;
  display: inline-block;
  letter-spacing: 1px;
  text-transform: uppercase;
}

/* Gradient underline effect */
.logo span::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -3px;
  width: 100%;
  height: 4px;
  background: linear-gradient(90deg, #3498db, #8e44ad);
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.3s ease-in-out;
}

/* Logo hover effect */
.logo span:hover {
  color: #3498db;
  text-shadow: 3px 3px 20px rgba(52, 152, 219, 0.4);
  transform: scale(1.05);
}

.logo span:hover::before {
  transform: scaleX(1);
  transform-origin: left;
}

/* NAV LINKS */
.navLinks {
  display: flex;
  list-style: none;
  gap: 20px;
}

.navLinks li {
  position: relative;
}

.navLink {
  text-decoration: none;
  color: #2c3e50;
  font-size: 1.3em;
  font-weight: 500;
  padding: 12px 16px;
  border-radius: 10px;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  cursor: pointer;
}

/* Hover & Active States */
.navLink:hover,
.navLink.active {
  background: linear-gradient(135deg, #3498db, #2980b9);
  color: #fff;
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(52, 152, 219, 0.3);
}

/* Underline effect */
.navLink::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 50%;
  background: #3498db;
  transition: all 0.3s ease;
  transform: translateX(-50%);
}

.navLink:hover::after {
  width: 100%;
}

/* MOBILE MENU ICON */
.menuIcon {
  display: none;
  flex-direction: column;
  cursor: pointer;
  padding: 10px;
  border-radius: 8px;
  transition: background 0.3s ease;
}

.menuIcon:hover {
  background: rgba(38, 230, 8, 0.9);
}

.bar {
  width: 28px;
  height: 2px;
  background-color: #2c3e50;
  margin: 3px 0;
  transition: 0.4s;
  border-radius: 3px;
}

/* OPEN MENU ANIMATION */
.menuIcon.open span:nth-child(1) {
  transform: translateY(7px) rotate(45deg);
  background-color: #3498db;
}

.menuIcon.open span:nth-child(2) {
  visibility: hidden; /* Prevents layout shifting */
}

.menuIcon.open span:nth-child(3) {
  transform: translateY(-7px) rotate(-45deg);
  background-color: #3498db;
}

/* MOBILE RESPONSIVENESS */
@media (max-width: 768px) {
  .navContainer {
    padding: 12px 30px;
  }

  .navLinks {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;
    background: rgba(255, 255, 255, 0.98);
    padding: 20px 0;
    text-align: center;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    transform: translateY(-10px);
    opacity: 0;
    transition: all 0.3s ease;
  }

  .navLinks.active {
    display: flex;
    transform: translateY(0);
    opacity: 1;
  }

  .navLinks li {
    margin: 15px 0;
  }

  .navLink {
    padding: 15px 30px;
    width: 80%;
    margin: 0 auto;
    display: block;
  }

  .menuIcon {
    display: flex;
  }
}

/* Animation for nav items */
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.navLinks li {
  animation: fadeInDown 0.5s ease forwards;
  animation-delay: calc(0.1s * var(--item-index));
}

/* New ones */
.logo {
  display: flex;
  align-items: center;
  gap: 10px; /* Space between logo and text */
  cursor: pointer;
}

.logoImage {
  width: 40px; /* Adjust as needed */
  height: auto;
  object-fit: contain;
}
