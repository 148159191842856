.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 1rem; /* Reduced padding */
  background: linear-gradient(180deg, #0f2027, #203a43, hwb(210 0% 98%));
  color: white;
  min-height: 200px;
}

.contactInfo {
  max-width: 600px;
  text-align: center;
}

.contactInfo h2 {
  margin-bottom: 2rem;
  font-size: clamp(1.5rem, 3vw, 2rem);
}

.contactButton {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.9rem 2rem;
  border-radius: 6px;
  font-weight: 600;
  font-size: 1.1rem;
  background: #0077b5;
  color: white;
  text-decoration: none;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: 0 auto;
}

.contactButton:hover {
  background: #005885;
  transform: translateY(-2px);
}

.contactButton img {
  width: 20px;
  height: 20px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .container {
    padding: 2rem 1rem;
    min-height: 300px;
  }

  .contactInfo {
    padding: 0 1rem;
  }

  .contactButton {
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 1.5rem 1rem;
    min-height: 250px;
  }

  .contactButton {
    padding: 0.7rem 1.2rem;
    width: 100%;
    justify-content: center;
  }
}
