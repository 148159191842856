/* Base Styles */
.aboutSection,
.featuredSection {
  width: 100%;
  padding: 4rem 2rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  position: relative;
}

.aboutSection {
  background: linear-gradient(180deg, #f8fafd, #e6eef8);
}

.featuredSection {
  background: linear-gradient(180deg, #9492923f, hwb(220 98% 0%), #f8fafd);
}

/* Container Styles */
.aboutContainer {
  width: 100%;
  max-width: 1400px;
  padding: 4rem;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(12px);
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.aboutContainer:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.12);
}

/* Typography */
.aboutContent,
.intro {
  max-width: 900px;
  margin: 0 auto;
  font-size: 1.2rem;
  color: #333;
  line-height: 1.8;
  font-weight: 400;
}

.intro {
  text-align: center;
  margin-bottom: 1.5rem;
}

.aboutTitle,
.featuredSection h2 {
  font-weight: 700;
  color: #1a1a1a;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
}

.aboutTitle {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.featuredSection h2 {
  font-size: 1.8rem;
  text-align: center;
  margin-bottom: 2rem;
}

/* Decorative Elements */
.aboutTitle::after,
.featuredSection h2::after {
  content: "";
  display: block;
  height: 4px;
  background: #0073e6;
  margin: 10px auto 0;
  border-radius: 2px;
}

.aboutTitle::after {
  width: 80px;
}

.featuredSection h2::after {
  width: 60px;
  background: #3b82f6;
  margin: 0.5rem auto 0;
}

/* Grid and Layout */
.featuredGrid {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 1300px;
  margin: 0 auto;
}

/* Logo Styles */
.aboutLogos,
.clientLogos {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  flex-wrap: wrap;
  margin-top: 2rem;
}

.aboutLogos img,
.clientLogos img,
.logo {
  height: 50px;
  filter: grayscale(100%);
  transition: all 0.3s ease-in-out;
}

.clientLogos img {
  opacity: 0.8;
}

.aboutLogos img:hover,
.clientLogos img:hover {
  filter: grayscale(0);
}

.clientLogos img:hover {
  transform: scale(1.1);
  opacity: 1;
}

/* Responsive Design */
@media (max-width: 768px) {
  .aboutSection,
  .featuredSection {
    padding: 3rem 1rem;
  }

  .aboutContainer {
    padding: 2rem;
  }

  .aboutTitle,
  .featuredSection h2 {
    font-size: 2rem;
  }

  .aboutContent,
  .intro {
    font-size: 1rem;
  }

  .aboutLogos img,
  .clientLogos img,
  .logo {
    height: 40px;
  }
}

@media (max-width: 480px) {
  .aboutSection,
  .featuredSection {
    padding: 1.5rem;
  }

  .aboutContainer {
    padding: 1.5rem;
  }

  .aboutTitle,
  .featuredSection h2 {
    font-size: 1.8rem;
  }

  .aboutContent,
  .intro {
    font-size: 0.9rem;
  }

  .aboutLogos img,
  .clientLogos img,
  .logo {
    height: 35px;
  }

  .aboutLogos,
  .clientLogos {
    gap: 1rem;
  }
}
