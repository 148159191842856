:root {
  --primary-color: #3498db;
  --secondary-color: #2ecc71;
  --text-color: #333;
  --background-color: #f4f4f4;
  --nav-height: 5rem;
  --container-width: 80%;
  --max-width: 1200px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  height: 100%;
  font-size: 16px;
}

body {
  font-family: "Arial", sans-serif;
  line-height: 1.6;
  background-color: var(--background-color);
  color: var(--text-color);
  min-height: 100%;
  overflow-x: hidden;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  position: relative;
}

main {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  /* Removed min-height here as it was causing spacing issues */
}

section {
  width: 100%;
  /* Removed min-height to prevent forced spacing */
  position: relative;
  /* Removed padding to prevent unwanted spacing */
}

.container {
  width: var(--container-width);
  max-width: var(--max-width);
  margin: 0 auto;
}

.navigation {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: var(--nav-height);
  z-index: 1000;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navLinks span {
  cursor: pointer;
  transition: color 0.3s ease;
}

/* Responsive Design */
@media (max-width: 1024px) {
  :root {
    --container-width: 90%;
  }
}

@media (max-width: 768px) {
  :root {
    --nav-height: 4rem;
  }

  html {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  :root {
    --container-width: 95%;
  }

  html {
    font-size: 12px;
  }
}
